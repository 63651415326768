import axios from "axios";
import router from '../router'
/**
 * 请求地址
 */
export const BASE_URL = `https://api.placebotrax.com`;


//请求拦截器,其余请求头加上token
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    console.log("ddd",token)
    if (config.url == "/admin/Login") {
        return config;
    } else {
        config.headers.authorization = token;
    }
    return config;
});
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
      const res = response.data;
      // token过期，重返登录界面
      if (res.code == 403) {
          localStorage.removeItem('token');
          router.push("Login");
      }
      return response;
  },
  (error) => {
      Message({
          message: error.msg,
          type: "error",
          duration: 5 * 1000,
      });
      return Promise.reject(error);
  }
);


/**
 * Get请求
 * @param {String} module 模块名
 * @param {String} action 接口名
 * @param {*} data 请求数据
 * @returns
 */
export const http_get = (module, action, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/${module}/${action}`, { params: data })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

/**
 * Post请求
 * @param {String} module 模块名
 * @param {String} action 接口名
 * @param {*} data 请求数据
 * @returns
 */
export const http_post = (module, action, data) => {
  const token = localStorage.getItem("token");
  let headers = {};
  if (token) {
    headers = { ...headers, authorization: token };
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/${module}/${action}`, data, {
        headers,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

/**
 * Post请求,上传文件
 * @param {String} module 模块名
 * @param {String} action 接口名
 * @param {*} data 请求数据
 * @returns
 */
export const http_post_file = (module, action, data) => {
  const token = localStorage.getItem("token");
  let headers = {"Content-Type": "multipart/form-data"};
  if (token) {
    headers = { ...headers, authorization: token };
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/${module}/${action}`, data, {
        headers,
        
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};






