import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        component: Login
    }, {
        path: "/user",
        component: () => import ("../components/HomeWel.vue"),
        name: "User",
        redirect: "/towardAudio",
        children: [
            {
                path: "/towardAudio",
                component: () => import ("../views/AudioManage/towardManage.vue"),
                mata: {
                    title: "朝"
                }
            },
            {
                path: "/eveningAudio",
                component: () => import ("../views/AudioManage/eveningManage.vue"),
                mata: {
                    title: "夕"
                }
            },
            {
                path: "/releaseAudio",
                component: () => import ("../views/AudioManage/releaseManage.vue"),
                mata: {
                    title: "夕"
                }
            },
            {
                path: "/floorAudio",
                component: () => import ("../views/AudioManage/floorManage.vue"),
                mata: {
                    title: "夕"
                }
            }, {
                path: "/eventManage",
                component: () => import ("../views/EventManage.vue"),
                mata: {
                    title: "事件管理"
                }
            }, {
                path: "/addEvent",
                component: () => import ("../views/AddEvent.vue"),
                mata: {
                    title: "发布事件"
                }
            },
                {
                    path: "/videoManage",
                    component: () => import ("../views/VideoManage.vue"),
                    mata: {
                        title: "视频管理"
                    }
                }
        ]
    },
];

const router = new VueRouter({routes});


export default router;


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
