import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/global.css";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入MD5
import md5 from "js-md5";

Vue.use(ElementUI);

Vue.prototype.$md5 = md5;

// 路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.path == "/") {
      next();
  } else {
      if (token == "" || token == null) {
          Vue.prototype
              .$confirm("未登录, 是否去登录?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
              })
              .then(() => {
                  router.push({ path: "/" }); // 未登录直接跳转登陆界面
                  // next('/')
              })
              .catch(() => {
                  next("/");
              });
      } else {
          next();
      }
  }
});

// 全局加载
Vue.prototype.openLoading = function() {
  const loading = this.$loading({
      // 声明一个loading对象
      lock: true, // 是否锁屏
      text: "加载中...", // 加载动画的文字
      spinner: "el-icon-loading", // 引入的loading图标
      background: "rgba(0, 0, 0, 0.5)", // 背景颜色
      target: ".sub-main", // 需要遮罩的区域
      body: true,
      customClass: "mask", // 遮罩层新增类名
  });
  setTimeout(function() {
      // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
      loading.close(); // 关闭遮罩层
  }, 15000);
  return loading;
};


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
