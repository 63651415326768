<template>
  <!-- 最外层盒子 -->
  <div class="bg">
    <!-- 上层框 -->
    <div class="top_box"></div>
    <div class="row">
      <!-- 登录输入框 -->
      <div class="left">
        <div class="image"><img src="../assets/img/loginbag.png" /></div>
        <p>音频后台管理系统</p>
      </div>
      <div class="formbox">
        <div class="title">管理员登陆</div>
        <!-- 登录信息输入表单 -->
        <el-form
          ref="rulesForm"
          label-width="80px"
          :model="msform"
          :rules="formRules"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名:" prop="username">
            <el-input
              v-model="msform.username"
              placeholder="请输入管理员用户名"
              max="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input
              type="password"
              v-model="msform.password"
              placeholder="请输管理员入密码"
            ></el-input>
          </el-form-item>
          <el-button type="success" round @click="login()">登陆</el-button>
          <el-button type="info" round @click="reset()">重置</el-button>
        </el-form>
      </div>
    </div>
    <!-- 底部信息栏 -->
    <div class="copyright">
      copyright&copy;&nbsp;&nbsp;2022-2022&nbsp;&nbsp;音频管理系统&nbsp;&nbsp;&nbsp;&nbsp;All
      Rights Reserved. 备案号：
      <a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备1112211号-1</a>
    </div>
  </div>
</template>

<script>
import { http_post } from "../utils/request.js";
export default {
  data() {
    return {
      msform: {
        // 用户名
        username: "",
        // 验证码
        password: "",
      },
      formRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      CodeState: false,
    };
  },
  methods: {
    // 用户登录
    async login() {
      let { username, password } = this.msform;
      if (this.msform.username && this.msform.password) {
        const rLoading = this.openLoading(); //打开loading界面
        const res = await http_post("admin", "Login", {
          username: username,
          password: this.$md5(password).toUpperCase(),
        });
        rLoading.close();
        if (res.code !== 200) {
          return this.$message.error("错误：" + res.msg);
        } else {
          this.$message({
            message: "登陆成功，欢迎使用",
            type: "success",
          });
          localStorage.setItem("token", res.data.token);
          sessionStorage.setItem("status", 1);
          this.$router.push("/User");
          this.reset();
        }
      } else {
        return this.$message.error("信息不能为空！");
      }
    },
    // 重置数据方法
    reset() {
      this.msform.username = "";
      this.msform.password = "";
    },
  },
};
</script>

<style lang="less" scoped>
.top_box {
  width: 100%;
  height: 15%;
}

.formbox {
  width: 49%;
  border-radius: 5px;
  margin-left: 30px;
  text-align: center;
  .title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    font-size: 35px;
    margin-top: 40px;
    text-align: center;
    letter-spacing: 3px;
    color: #333;
  }
}
.bg {
  width: 100%;
  height: 100%;
  background: url(../assets/img/background.jpg) no-repeat;
  background-size: cover;
  // background:#7babb1
}
span {
  float: left;
  display: block;
  padding-left: 30px;
  line-height: 60px;
  font-size: 30px;
  color: white;
}
.top {
  min-width: 1200px;
  letter-spacing: 0.3em;
  height: 60px;
  background: #3c5a6e;
  .el-button {
    float: right;
    margin-right: 150px;
    margin-top: 10px;
  }
  a {
    text-decoration: none;
    color: white;
  }
}
.left {
  width: 50%;
  height: 100%;
  background: #ececec;
  .image {
    width: 80%;
    margin: 60px auto;
  }
  img {
    width: 90%;
    height: 90%;
  }
  p {
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    color: #333;
    letter-spacing: 4px;
  }
}
.row {
  background: #ffffff;
  display: flex;
  justify-content: center;
  border: #c4c4c4 solid 1px;
  width: 1200px;
  height: 600px;
  margin: 0 auto;
}

.el-form {
  margin-top: 50px;
  .el-form-item {
    margin-top: 50px;
  }
  .el-input {
    width: 400px;
  }
}
.btn1 {
  margin-top: 100px;
}
.el-button {
  width: 300px;
  height: 50px;
  font-size: 24px;
  margin-top: 30px;
}
.copyright {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  background-color: rgb(170, 170, 170);
  color: white;
}
</style>
